import { getServerRequest } from '@/helpers/serverRequest.helper';
import { store } from '@/store/index';
const httpURL = store.getters.httpURL;

export const getModulosService = async() => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/modulos/dashboard`;
    return await getServerRequest(url, config);
}


import {
    amplitudVisualItem,
    velocidadVisualItem,
    retencionItem,
    lecturasItem
} from '../../modulos/modulos.data'

export function getDashboardModulos(modoOscuro) {
    let modulos = [];
    modoOscuro;
    modulos.push(amplitudVisualItem);
    modulos.push(velocidadVisualItem);
    modulos.push(retencionItem);
    modulos.push(lecturasItem);
    return modulos;
}