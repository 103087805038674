<template>
  <v-menu transition="slide-y-transition" offset-y bottom>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot name="activator"></slot>
      </div>
    </template>
    <v-list :color="modoOscuro ? 'blue-grey darken-1' : ''" :dark="modoOscuro">
      <template v-for="(item, index) in items">
        <v-list-item :key="`menuIntem-${index}`" @click="emitAction(item)">
          <v-list-item-icon v-if="item.icon" style="margin-right: 15px;">
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>

import { mapGetters } from "vuex";


export default {
  props: ["items"],
  computed: {
    ...mapGetters(["modoOscuro"])
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    emitAction(item) {
      this.$emit(item.action, item.id);
    },
  },
};
</script>
