<template>
  <v-card
    flat
    :outlined="modoOscuro"
    :dark="modoOscuro"
    :color="modoOscuro ? '#002969' : 'grey lighten-3'"
  >
    <v-card-title>Módulos</v-card-title>
    <v-container>
      <v-row v-if="loading">
        <v-col
          v-for="number in 4"
          :key="`loading-module-${number}`"
          cols="12"
          sm="12"
          md="6"
        >
          <v-skeleton-loader
            class="mx-auto"
            max-width="300"
            max-height="150"
            type="card"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col
          v-for="(modulo, index) in modulos"
          :key="index"
          cols="12"
          sm="12"
          md="6"
        >
          <v-card
            dark
            :color="modoOscuro ? modulo.colorOscuro : modulo.colorClasico"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon large>{{ modulo.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="headline">{{
                  modulo.titulo
                }}</v-list-item-title>
                <v-list-item-subtitle class="title">
                  <span v-if="loading">Cargando...</span>
                  <span v-else>{{ modulo.nivel }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <div class="progress-bar-modulo">
              <v-progress-linear
                color="white"
                :value="modulo.porcentaje"
                :indeterminate="loading"
              >
              </v-progress-linear>
            </div>
            <v-card-actions style="padding-left:20px;">
              {{ modulo.porcentaje }}%
              <v-spacer></v-spacer>
              <v-btn
                class="ml-2 mt-5"
                outlined
                rounded
                small
                @click="goToModulo(modulo.route)"
              >
                Ingresar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { getModulosService } from "./dashModulos.service";

export default {
  name: "modulosCurso",
  computed: {
    ...mapGetters(["httpURL", "sessionToken", "modoOscuro"]),
  },
  data() {
    return {
      loading: false,
      modulos: [],
    };
  },
  async mounted() {
    this.cargarModulos();
  },
  methods: {
    async cargarModulos() {
      try {
        this.loading = true;
        const serverResponse = await getModulosService();
        this.$validateResponse(serverResponse);
        this.loading = false;
        this.modulos = serverResponse.modulos;
      } catch (error) {
        this.$appError(error.message);
      }
    },
    goToModulo(moduloNombre) {
      this.$router.push(`/modulo/${moduloNombre}`);
    },
  },
};
</script>

<style>
.flat-link {
  text-decoration: none;
}
.progress-bar-modulo {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
#login-bg {
  height: 100vh;
  width: 100vw;
  background-color: #0d47a1;
  background-image: linear-gradient(10deg, #81d4fa 50%, transparent 50%),
    linear-gradient(-60deg, #5e35b1 30%, transparent 30%);
}
</style>
