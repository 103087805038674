<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <userBannerComponent />
      </v-col>
      <v-col cols="12" md="7" sm="12">
        <modulosCursoComponent />
      </v-col>
      <v-col cols="12" md="5" sm="12">
        <actividadSugeridaComponent />
      </v-col>
      <!-- <v-col cols="6">
        <listaLogrosComponent />
      </v-col>
      <v-col cols="6">
        <listaRetosComponent />
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import userBannerComponent from "./userBanner";
import modulosCursoComponent from "./dashModulos/dashModulos";
import actividadSugeridaComponent from "./actividadSugerida/actividadSugerida";
// import listaLogrosComponent from "./listaLogros";
// import listaRetosComponent from "./listaRetos";

export default {
  name: "dashboardView",
  components: {
    userBannerComponent,
    modulosCursoComponent,
    actividadSugeridaComponent,
    // listaLogrosComponent,
    // listaRetosComponent,
  },
  computed: {
    ...mapGetters(["modoOscuro"]),
  },
  data() {
    return {
      loading: false,
    };
  },
  created() {},
  methods: {},
};
</script>

<style>
.flat-link {
  text-decoration: none;
}
</style>
