<template>
  <v-card
    flat
    :outlined="modoOscuro"
    :dark="modoOscuro"
    :color="modoOscuro ? '#002969' : 'grey lighten-3'"
    style="padding-bottom: 5px;"
  >
    <v-card-title>Actividades sugeridas</v-card-title>

    <v-card
      v-for="(actividad, index) in actividades"
      :key="`as-${index}`"
      :color="modoOscuro ? actividad.colorOscuro : actividad.colorClasico"
      flat
      dark
      style="margin: 10px; margin-bottom:10px;"
    >
      <v-skeleton-loader
        v-if="loading"
        :light="!modoOscuro"
        type="list-item-avatar-two-line"
      ></v-skeleton-loader>

      <v-list-item v-else>
        <v-list-item-icon>
          <v-icon>{{ actividad.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ actividad.nombre }}</v-list-item-title>
          <v-list-item-subtitle>{{
            actividad.tituloModulo
          }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon
            ><v-icon>mdi-arrow-right-bold-circle-outline</v-icon></v-btn
          >
        </v-list-item-action>
      </v-list-item>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { getActividadSugerida } from "./actividadSugerida.service";

export default {
  name: "actividadSugerida",
  computed: {
    ...mapGetters(["modoOscuro"]),
  },
  data() {
    return {
      loading: false,
      actividades: [],
    };
  },
  mounted() {
    this.cargarActividadesSugeridas();
    },
  methods: {
    async cargarActividadesSugeridas(){
      this.loading = true;
      this.actividades = await getActividadSugerida();
      this.loading = false;
    }
  },
};
</script>

<style>
.flat-link {
  text-decoration: none;
}
#login-bg {
  height: 100vh;
  width: 100vw;
  background-color: #0d47a1;
  background-image: linear-gradient(10deg, #81d4fa 50%, transparent 50%),
    linear-gradient(-60deg, #5e35b1 30%, transparent 30%);
}
</style>
