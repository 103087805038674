<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        dark
        fab
        outlined
        color="cyan lighten-3"
        :loading="loading"
        v-bind="attrs"
        v-on="on"
        @click="cerrarSesion"
        ><v-icon>mdi-power</v-icon>
      </v-btn>
    </template>
    <span>Salir</span>
  </v-tooltip>
</template>

<script>
import { logOut } from "./userBanner.service";
import { mapGetters } from "vuex";

export default {
  name: "logoutButton",
  computed: {
    ...mapGetters(["httpURL", "sessionToken"]),
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    async cerrarSesion() {
      try {
        this.loading = true;
        const serverResponse = await logOut({});
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) this.$appError(serverResponse.mensaje);
        else {
          this.$store.dispatch("setSessionToken", null);
          localStorage.removeItem("hiperToken");
          this.$router.push("/login");
        }
      } catch (error) {
        this.loading = false;
        this.$appError(error.mensaje);
      }
    },
  },
};
</script>
