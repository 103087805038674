import { modulosObjectList } from '../../modulos/modulos.data'

export async function getActividadSugerida() {

    let actividades = [];
    for (let actividad of dummyData) {
        let nuevaActividad = setModuloActividad(actividad.modulo, actividad);
        actividades.push(nuevaActividad);
    }
    return actividades;
}

function setModuloActividad(nombreModulo, actividad) {

    actividad.colorOscuro = modulosObjectList[nombreModulo].colorOscuro;
    actividad.colorClasico = modulosObjectList[nombreModulo].colorClasico;
    actividad.icon = modulosObjectList[nombreModulo].icon;
    actividad.tituloModulo = modulosObjectList[nombreModulo].titulo;
    return actividad;
}

const dummyData = [{
        nombre: "Nombre del ejercicio",
        modulo: "amplitudVisual"
    },
    {
        nombre: "Nombre del ejercicio",
        modulo: "velocidadVisual"
    },
    {
        nombre: "Nombre del ejercicio",
        modulo: "retencion"
    },
    {
        nombre: "Nombre del ejercicio",
        modulo: "lecturas"
    },
]