export const amplitudVisualItem = {
    nombre: "amplitudVisual",
    titulo: "Amplitud visual",
    icon: "mdi-eye-plus",
    colorClasico: "pink darken-2",
    colorOscuro: "pink darken-3",
    nivel: "Principiante",
    route: "amplitud-visual"
}

export const velocidadVisualItem = {
    nombre: "velocidadVisual",
    titulo: "Velocidad visual",
    icon: "mdi-clock-fast",
    colorClasico: "purple darken-2",
    colorOscuro: "purple darken-3",
    nivel: "Principiante",
    route: "velocidad-visual",
}

export const retencionItem = {
    nombre: "retencion",
    titulo: "Retención",
    icon: "mdi-head-snowflake",
    colorClasico: "green darken-2",
    colorOscuro: "green darken-3",
    nivel: "Principiante",
    route: "retencion",
}

export const lecturasItem = {
    nombre: "lecturas",
    titulo: "Lecturas",
    icon: "mdi-text-box",
    colorClasico: "light-blue darken-2",
    colorOscuro: "light-blue darken-3",
    nivel: "Principiante",
    route: "lecturas",
}

export const modulosObjectList = {
    amplitudVisual: amplitudVisualItem,
    velocidadVisual: velocidadVisualItem,
    retencion: retencionItem,
    lecturas: lecturasItem,
}

export const modulosArray = [
    amplitudVisualItem,
    velocidadVisualItem,
    retencionItem,
    lecturasItem
];

export const amplitudVisualInstructions = `
<h3>Tus ojos obtienen más información de la que te imaginas.</h3>
<br>
<p>
En este mismo momento estás viendo la pantalla de tu dispositivo y sin embargo puedes obtener información de lo que hay a tu alrededor sin necesidad de prestarle atención gracias a tu vista periférica.
</p>
<p>
¿Has notado que cuando leemos, comúnmente lo hacemos palabra por palabra? Imagina que pudieras leer tres o más palabras de un vistazo en lugar de una.
La base del método de lectura dinámica es precisamente el uso entrenado de la vista periférica para poder obtener más información con un solo enfoque de vista.
</p>
<p>
Los ejercicios de Amplitud Visual te permitirán trabajar con tu vista periférica y entrenarla para que puedas adquirir mayor información en un sólo vistazo.
Selecciona los ejercicios en esta página, los cuales se van a desbloquear de acuerdo a tu desempeño para asegurar que estás adquiriendo la habilidad necesaria. Éstos subirán de nivel dependiendo tu avance.
</p>
`;

export const velocidadVisualInstructions = `
<h3>La vista es más rápida que la mano.</h3>
<br>
<p>
En el proceso de lectura dinámica es escencial adquitir una velocidad visual que te permita recabar más información en menos tiempo.
Los ejercicios de este módulo te ayudarán a entrenar tu vista y comunicarla con el cerebro de una manera más rápida y efectiva para obtener mejores resultados.
</p>
`;

export const retencionInstructions = `
<h3>La retención de información, el complemento final.</h3>
<br>
<p>
La retención en la lectura dinámica es de bastante importancia. Podríamos leer muy rápido pero sin retener la información sería un tiempo perdido. 
Los ejercicios de este módulo nos ayudarán a trabajar con nuestra memoria a corto plazo para retener información mientras leemos.
</p>
<p>
Los ejercicios de retención presentados en este módulo no sólo te servirán para aplicar el principio de lectura dinámica, sino también para tu vida cotidiana ayudando a no olvidar información en un corto periodo de tiempo.
</p>
`;

export const lecturasInstructions = `
<h3>¿Estás listo para leer rápido y comprenderlo todo?</h3>
<br>
<p>
En esta sección utilizarás la técnica de lectura dinámica al 100%, para ello se te presentarán distintas lecturas que deberás leer más rápido de lo que normalmente acostumbras.
</p>
<p>
Para ello, deberás confiar en ti mism@. El sistema te guiará y tú deberás leer por bloques en lugar de palabras.
</p>
<p>
Puede que al comienzo te parezca muy rápido, pero no te desesperes esto es algo nuevo y entre más practiques, la técnica se irá perfeccionando.
</p>
`;