<template>
  <div>
    <menuSimpleComponent
      :items="menuAvatar"
      @cambiarImagenPerfil="cambiarImagenPerfil"
      @cambiarModoOscuro="cambiarModoOscuro"
      @cambiarModoClasico="cambiarModoOscuro"
      @cambiarPassword="cambiarPassword"
    >
      <v-badge
        bordered
        color="blue"
        icon="mdi-chevron-down"
        slot="activator"
        overlap
        bottom
      >
        <v-avatar size="60">
          <v-img :src="userPicture"></v-img>
        </v-avatar>
      </v-badge>
    </menuSimpleComponent>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { getMenuOptions, cambiarModoOscuro } from "./avatarMenu.service";
import menuSimpleComponent from "../../../template/menuSimple";

export default {
  name: "avatarMenu",
  components: { menuSimpleComponent },
  computed: {
    ...mapGetters([
      "modoOscuro",
      "userAvatar",
      "googleUser",
      "appleUser",
    ]),
    menuAvatar() {
      return getMenuOptions(this.modoOscuro, this.googleUser, this.appleUser);
    },
    userPicture() {
      let picture = require("@/assets/unknown-user-circle.png");
      if (this.googleUser || this.appleUser) picture = this.userAvatar;
      return picture;
    },
  },
  data() {
    return {
      loading: false,
      menu: [],
    };
  },
  methods: {
    cambiarImagenPerfil() {
      console.log("cambiarImagenPerfil");
    },
    async cambiarModoOscuro() {
      try {
        this.$store.dispatch("setModoOscuro", !this.modoOscuro);
        const sendData = {
          modoOscuro: !this.modoOscuro,
        };
        this.loading = true;
        const serverResponse = await cambiarModoOscuro(sendData);
        this.$validateResponse(serverResponse);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$appError(error.mensaje);
      }
    },
    cambiarPassword() {
      console.log("cambiarPassword");
    },
  },
};
</script>
