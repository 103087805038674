import { putServerRequest } from '@/helpers/serverRequest.helper';
import { store } from '@/store/index';
const httpURL = store.getters.httpURL;

export function getMenuOptions(modoOscuro, googleUser, appleUser) {
    let menu = [];
    menu.push(estadisticosItem);
    if (!googleUser && !appleUser)
        menu.push(imagenPerfilItem);
    if (!modoOscuro)
        menu.push(modoOscuroItem)
    else
        menu.push(modoClasicoItem)
    if (!googleUser && !appleUser)
        menu.push(cambiarPasswordItem)

    return menu;
}

export const cambiarModoOscuro = async(data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/preferencias/modoOscuro`;
    return await putServerRequest(url, data, config);
}

const estadisticosItem = {
    title: "Estadísticos",
    action: "cambiarImagenPerfil",
    icon: "mdi-chart-bar"
}

const imagenPerfilItem = {
    title: "Imagen de perfil",
    action: "cambiarImagenPerfil",
    icon: "mdi-account-circle"
}

const modoOscuroItem = {
    title: "Modo oscuro",
    action: "cambiarModoOscuro",
    icon: "mdi-view-dashboard"
}

const modoClasicoItem = {
    title: "Modo clásico",
    action: "cambiarModoClasico",
    icon: "mdi-view-dashboard"
}

const cambiarPasswordItem = {
    title: "Cambio de contraseña",
    action: "cambiarPassword",
    icon: "mdi-lock-reset"
}