<template>
  <div>
    <v-card
      shaped
      dark
      flat
      :color="modoOscuro ? 'indigo darken-3' : 'indigo darken-2'"
    >
      <v-list-item>
        <v-list-item-icon>
          <avatarMenuComponent />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <h3>{{ userName }}</h3>
          </v-list-item-title>
          <v-list-item-subtitle
            >Velocidad de lectura:
            <strong>100 ppm</strong></v-list-item-subtitle
          >
          <v-list-item-subtitle
            >Promedio de comprensión: <strong>50%</strong></v-list-item-subtitle
          >
        </v-list-item-content>
        <v-list-item-action class="pts">
          {{ptsFormat}} pts.
        </v-list-item-action>
        <v-list-item-action>
          <logoutButtonComponent />
        </v-list-item-action>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import anime from "animejs/lib/anime.es.js";
import numeral from 'numeral';

import avatarMenuComponent from "./avatarMenu/avatarMenu";
import logoutButtonComponent from "./logoutButton";

export default {
  components: {
    avatarMenuComponent,
    logoutButtonComponent,
  },
  computed: {
    ...mapGetters(["modoOscuro", "userName"]),
    ptsFormat(){
      return numeral(this.ptsLocal).format('0,0')
    }
  },
  data() {
    return {
      loading: false,
      ptsLocal: 1000
    };
  },
  mounted() {
    // if(this.ptsLocal > 100)
    //   this.refreshPts();
  },
  methods: {
    refreshPts() {
      // let element = this.$refs.userPts;
      // let ptsLocal = this.ptsLocal;
      // const porcentaje = ptsLocal/100;
      // anime({
      //   targets: this.$refs.userPts,
      //   innerHTML: [0, 10000],
      //   easing: "linear",
      //   round: 1,
      //   update: function(anim) {
      //     const progress = Math.round(anim.progress*porcentaje);
      //     element.innerHTML = progress+' pts';
      //   },
      //   complete: function() {
      //     element.innerHTML = '1,000 pts';
      //   }
      // });
    },
  },
};
</script>

<style>
.pts {
  font-weight: bold;
}
</style>
